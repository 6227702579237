import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../components/seo'

export default function Template({
    data, // this prop will be injected by the GraphQL query below
}) {

    // the following is equivalent to
    // const markdown = data.markdownRemark
    const { markdownRemark } = data // data.markdownRemark holds your post data

    const { frontmatter, html, excerpt } = markdownRemark // markdownRemark.frontmatter, markdownRemark.html

    const featuredImage = frontmatter.featuredImage

    const { ogImage } = frontmatter
    const ogImagePath = ogImage && ogImage.childImageSharp.fixed.src

    return (
        <>

            <SEO 
                title={frontmatter.title}
                description={excerpt}
                path={frontmatter.slug}
                image={ogImagePath}
            />

            <div className="container">
                <div 
                    className="row"
                    style={
                        {
                            background: "rgba(0, 0, 0, 0.5)",
                            padding: "5% 5% 5% 5%",
                            marginTop: "3em",
                            marginBottom: "3em"
                        }
                    }
                >
                    <div className="col s12">
        
                        <header>
                            <h1 className="eggshell-text">{frontmatter.title}</h1>
                            <p className="eggshell-text"><small>{frontmatter.author} on {frontmatter.date}</small></p>
                        </header>
        
                        {/* <div
                            className="blog-post-content"
                            dangerouslySetInnerHTML={{ __html: html }}
                        /> */}
        
                        <main>
                            <article>
                                {
                                    featuredImage !== null && <Img fluid={featuredImage.childImageSharp.fluid} />
                                }
                                <div
                                    className="eggshell-text blog-post-content" 
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </article>         
                        </main>
        
                    </div>
                </div>
            </div>
            
        </>
    )

}

export const pageQuery = graphql`

    query ($slug: String!){
        markdownRemark(frontmatter: { slug: { eq: $slug }}) {
            html
            excerpt(pruneLength: 250)
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                author
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                ogImage { 
                    childImageSharp {
                        fixed {
                            src
                        }
                    }
                }
            }
        }
    }

`